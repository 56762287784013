let  env = require("../../../env")
// 用户中心url
let  url_usercenter = env[process.env.environment].VUE_APP_PERSONURL
// 金融服务
let  url_business = env[process.env.environment].VUE_FINANCESERVICE
// 招采url
let  url_zhaocai = env[process.env.environment].VUE_APP_TENDERURL
// 企业服务url
let  url_enterpriseService = env[process.env.environment].qiye_SERVICE
// 工队url
let  url_team = env[process.env.environment].VUE_APP_TEAM_URL
// 集采url
let  url_jicai = env[process.env.environment].VUE_APP_GROUNPBUYING
// 租赁url
let  url_zulin = env[process.env.environment].VUE_APP_RENTALMARKET
// 物流url
let  url_wuliu = env[process.env.environment].VUE_APP_LOGISTICSURL
// 企业url
let  url_qiye = env[process.env.environment].qiye_RPOUTE_API
let  url_qiantai = env[process.env.environment].VUE_APP_MATERIALSURL
let  url_vip = env[process.env.environment].VUE_APP_HUIYUAN
export const exchangeEnum = [
  {
    name: "商品需求",
    img: require("@/assets/img/home/new1/need_office.png"),
    path: `${url_qiantai}/demandHallList`,
  },
  {
    name: "竞价采购",
    img: require("@/assets/img/home/new1/biddingbuy.png"),
    path: `${url_zhaocai}/direct-jb`,
  },
  {
    name: "招标大厅",
    img: require("@/assets/img/home/new1/tender_notice.png"),
    path: `${url_zhaocai}/zb`,
  },
  {
    name: "需求预算",
    img: require("@/assets/img/home/new1/need_icon.png"),
    path: `${url_usercenter}`,
    pathAfter: `myDemandList`
  },
  {
    name: "需方发起",
    img: require("@/assets/img/home/new1/demand_analysis.png"),
    path: `${url_jicai}/jiCaiLobby`,
  },
  {
    name: "租赁需求",
    img: require("@/assets/img/home/new1/jobintention_icon.png"),
    path: `${url_zulin}/demand`,
  },
  {
    name: "货运大厅",
    img: require("@/assets/img/home/new1/freightbuilding.png"),
    path: `${url_wuliu}/trunk?active=1`,
  },
  {
    name: "企业资质",
    img: require("@/assets/img/home/new1/com_certification.png"),
    path: `${url_usercenter}`,
    pathAfter: `companyAptitude`
  },
  {
    name: "企业大全",
    img: require("@/assets/img/home/new1/company_all.png"),
    path: `${url_qiye}/businessHall`,
  },
  {
    name: "知识产权",
    img: require("@/assets/img/home/new1/intellectual_property.png"),
    path: `${url_qiye}/intellectualProperty?tab=1`,
  },
  {
    name: "服务大厅",
    img: require("@/assets/img/home/new1/service_icon.png"),
    path: `${url_enterpriseService}/serviceFirm`,
  },
  {
    name: "酒店管理",
    img: require("@/assets/img/home/new1/professional_team.png"),
    path: `${url_enterpriseService}/team`,
  },
]
export const modulesEnum = [
  // {
  //   name: "我的企业",
  //   img: require("@/assets/img/home/new1/baojia.png"),
  //   path: `${url_usercenter}`,
  //   pathAfter: `UserEnterpriseList`,
  //   isCompanyAuth: true
  // },
  {
    name: "我的网站",
    img: require("@/assets/img/home/new1/mynet_icon.png"),
    path: `${url_qiantai}`,
    // pathAfter: `personWebsite`,
    isCompanyAuth: true
  },
  {
    name: "用户中心",
    img: require("@/assets/img/home/new1/user.png"),
    path: `${url_usercenter}`,
    pathAfter: `financeModify`,
    isCompanyAuth: true
  },
  {
    name: "会员经营",
    img: require("@/assets/img/home/new1/huiyuan.png"),
    path: `${url_vip}`,
    pathAfter: "MemberBusinessC"
  },
  {
    name: "采购竞价",
    img: require("@/assets/img/home/new1/mybidding.png"),
    path: `${url_usercenter}/CompeteBiddingList`,
    pathAfter: `CompeteBiddingList`
  },
  {
    name: "集采报价",
    img: require("@/assets/img/home/new1/wodebaojia.png"),
    path: `${url_usercenter}/QuotePrice`,
    pathAfter: `QuotePrice`
  },
  {
    name: "金融服务",
    img: require("@/assets/img/home/new1/business_service.png"),
    path: `${url_business}`,
    pathAfter: "supply-product"
  },
  {
    name: "劳务用工",
    img: require("@/assets/img/home/new1/labor_subcontracting.png"),
    path: `${url_usercenter}`,
    pathAfter: `demandList`
  },
  {
    name: "竞价货运",
    img: require("@/assets/img/home/new1/biddinggoods.png"),
    path: `${url_wuliu}/trunk?active=1&childActive=1`,
    pathAfter: `BiddingGoodsManageList`
  },
  {
    name: "我的询价",
    img: require("@/assets/img/home/new1/wodexunjia.png"),
    path: `${url_usercenter}/OurInquiryPriceList`,
    pathAfter: `OurInquiryPriceList`
  },
  {
    name: "竞价找车",
    img: require("@/assets/img/home/new1/biddingcar.png"),
    path: `${url_wuliu}/trunk?active=2&childActive=1`,
  },
  {
    name: "我的招采",
    img: require("@/assets/img/home/new1/wodezhaobiao.png"),
    path: `${url_usercenter}/tenderingmanagement`,
    pathAfter: `tenderingmanagement`
  },
  
  // {
  //   name: "临时用工",
  //   img: require("@/assets/img/home/new1/entertain_use.png"),
  //   path: `${url_usercenter}/flexWorkerList`,
  //   pathAfter: `flexWorkerList`
  // },
  
  
  
  // {
  //   name: "专业代办",
  //   img: require("@/assets/img/home/new1/my_service.png"),
  //   path: `${url_enterpriseService}/serve`,
  //   // pathAfter: `serveList`
  // },
  {
    name: "企业集采",
    img: require("@/assets/img/home/new1/qiyejicai_icon.png"),
    path: `${url_jicai}/enterpriseSpecial`,
  },
]
