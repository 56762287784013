//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { apiHome } from "@/api/apiHome";
import apiLogistics from "@/api/apiLogistics";
import cookie from "@/storage/cookies.js";
import { AppConfig } from "@/config";
import category from "./components/category";
import bannerRight from "./components/modulesContent";
import Banner from "./components/Banner";
import Menu from "@/components/public/Menu";
import notice from "./components/notice";
import goods from "./components/goods";
import tender from "./components/tender";
import Lease from "./components/lease";
var env = require("../../../env");
import volunteerTeam from "./components/volunteerTeam";
import flowModel from "@/pages/Home/components/flowModel";
import groupBuying from "./components/groupBuying/index";
import CompanyService from "@/pages/Home/components/companyService/index.vue";
import enterpriseService from "@/pages/Home/components/enterpriseService";
import knowledgeShare from "./components/zsfx";
import investment from "./components/investment/index.vue";
const htmlDom = document.getElementsByTagName('html')[0]
export default {
  name: "home",
  components: {
    Menu, // 导航菜单
    category, //销售类目
    Banner, // 轮播图
    notice, // 公告
    bannerRight,
    modules: () => import("./components/modules"),
    ScrollNavBar: () => import("./components/ScrollNavBar"),
    goods, //商品/材料
    tender, //招标/竞标
    Lease, //租赁服务
    groupBuying, //集采服务
    volunteerTeam, //工队服务
    logistics: () => import("./components/logistics"), //货物物流
    knowledgeShare, //知识分享
    "recommend-enterprise": () => import("./components/recommendEnterprise"), //优质企业
    platformAdvantage: () =>
      import("@/pages/Home/components/platformAdvantage"), //平台优势
    flowModel, //物流模块
    enterpriseService, // 企业服务
    CompanyService,
    investment,
    AdverSlider: () => import("@/pages/Home/components/sliderAdv/index") //侧边广告
  },
  data: function() {
    return {
      activeIndex: -1,
      bannerList: [],
      silderList: {
        leftPicList: [],
        rightPicList: []
      },
      categoryLoading: false,
      categoryList: [],
      busy: false,
      shouModules: 0
    };
  },
  middleware: "clientCookie",
  async asyncData({ app, store }) {},
  methods: {
    // 获取banner列表
    async init() {
      {
        // const cookieRes = app.$cookies.getAll();
        let // menuList = [],
          categoryList = [],
          bannerList = [],
          silderList = []
        // try {
        //   let res = await apiHome.getNewCatList();
        //   categoryList = (res.data && res.data.data) || [];
        //   // let banner = (await apiLogistics.homepageBanner()) || {};
        //   // let bannerList = (banner.data && banner.data.data) || [];
        // } catch (error) {
        //   categoryList = [];
        // }
        try {
          const silderAdv = await apiHome.getSliderAdverList(parmas);
          const { code: c, data: result, msg: Msg } = silderAdv.data;
          if (+c === 200) {
            silderList = (result && result) || {
              leftPicList: [],
              rightPicList: []
            };
          }
        } catch (error) {
          bannerList = [];
          silderList = { leftPicList: [], rightPicList: [] };
        }
        // return {
        //   // menuList,
        //   categoryList,
        //   bannerList,
        //   silderList,
        //   cookieRes,
        // };
        this.categoryList = categoryList;
        this.bannerList = bannerList;
        this.silderList = silderList;
      }
    },
    async getBannerList() {
      let cookieArea = cookie.cookieRead("area");
      let area = cookieArea ? JSON.parse(cookieArea) : {};
      const { id } = area;
      const parmas = {
        type: 1,
        regionId: id
      };
      const res = await apiHome.getBannerAdverList(parmas);
      const { code, data, msg } = res.data;
      if (+code === 200) {
        const list =
          data &&
          data.map(item => {
            return {
              bannerImg: item.pic,
              url: item.url
            };
          });
        this.bannerList = list;
        return;
      }
    },
    // 获取侧边广告列表
    async getAlisdList() {
      let cookieArea = cookie.cookieRead("area");
      let area = cookieArea ? JSON.parse(cookieArea) : {};
      const { id } = area;
      const parmas = {
        type: 1,
        regionId: id
      };
      const res = await apiHome.getSliderAdverList(parmas);
      const { code, data, msg } = res.data;
      if (+code === 200) {
        this.silderList = (data && data) || {
          leftPicList: [],
          rightPicList: []
        };
        return;
      }
    },
    clickLeftBar({ index, name }) {
      this.removeScroll = true;
      console.log(index)
      const promise = new Promise((resolve, reject) => {
        if(this.shouModules < index + 1){
          this.shouModules = index + 1;
        }
        this.$nextTick(()=>{
          const dom = this.$refs[`scrollDom_${index}`].$el;
          this.activeIndex = index;
          window.scrollTo(0, dom.offsetTop + 870 - 82);
          return resolve();
          // if (dom) {
          //   dom.scrollIntoView({
          //     block: "start",
          //     behavior: "smooth",
          //   });
          //   return resolve();
          // }
          return reject();
        })
      });
      promise.then(() => {
        setTimeout(() => {
          this.removeScroll = false;
        }, 1000);
      });
    },
    scrollListener() {
      this.$nextTick(() => {
        try {
          document.addEventListener("scroll", () => {
            const dom0 = this.$refs[`scrollDom_0`]?.$el;
            const dom1 = this.$refs[`scrollDom_1`]?.$el;
            const dom2 = this.$refs[`scrollDom_2`]?.$el;
            const dom3 = this.$refs[`scrollDom_3`]?.$el;
            const dom4 = this.$refs[`scrollDom_4`]?.$el;
            const dom5 = this.$refs[`scrollDom_5`]?.$el;
            const dom6 = this.$refs[`scrollDom_6`]?.$el;
            const dom7 = this.$refs[`scrollDom_7`]?.$el;
            const dom8 = this.$refs[`scrollDom_8`]?.$el;
            const dom9 = this.$refs[`scrollDom_9`]?.$el;
            if (this.removeScroll) return;
            if (
              dom0 &&
              dom0.getBoundingClientRect().top <=
              dom0.getBoundingClientRect().height / 2
            ) {
              this.activeIndex = 0;
            } else {
              this.activeIndex = -1;
            }
            if (
              dom1 &&
              dom1.getBoundingClientRect().top <=
              dom1.getBoundingClientRect().height / 2
            ) {
              this.activeIndex = 1;
            }
            if (
              dom2 &&
              dom2.getBoundingClientRect().top <=
              dom2.getBoundingClientRect().height / 2
            ) {
              this.activeIndex = 2;
            }
            if (
              dom3 &&
              dom3.getBoundingClientRect().top <=
              dom3.getBoundingClientRect().height / 2
            ) {
              this.activeIndex = 3;
            }
            if (
              dom4 &&
              dom4.getBoundingClientRect().top <=
              dom4.getBoundingClientRect().height / 2
            ) {
              this.activeIndex = 4;
            }
            if (
              dom5 &&
              dom5.getBoundingClientRect().top <=
              dom5.getBoundingClientRect().height / 2
            ) {
              this.activeIndex = 5;
            }
            if (
              dom6 &&
              dom6.getBoundingClientRect().top <=
              dom6.getBoundingClientRect().height / 2
            ) {
              this.activeIndex = 6;
            }
            if (
              dom7 &&
              dom7.getBoundingClientRect().top <=
              dom7.getBoundingClientRect().height / 2
            ) {
              this.activeIndex = 7;
            }
            if (
              dom8 &&
              dom8.getBoundingClientRect().top <=
              dom8.getBoundingClientRect().height / 2
            ) {
              this.activeIndex = 8;
            }
            if (
              dom9 &&
              dom9.getBoundingClientRect().top <=
              dom9.getBoundingClientRect().height / 2
            ) {
              this.activeIndex = 9;
            }
          });
        } catch (error) {
          console.log(error);
        }
      });
    },
    loadMore(){
      this.busy = true
      this.shouModules = this.shouModules + 1
      this.busy = this.shouModules > 9
      console.log('loadmore ============>>>>>>>>>>>>>>>>>', this.shouModules)
    },
    // 判断是否开启黑白滤镜
    async handleBlackWhiteFilter(){
      let params = {
        pcTaget:'BIG_HOME_PAGE'
      }
      apiHome.getPcHomePageBlack(params).then(({ data }) => {
        if(data.code === '200'){
          if(data.data.enabled === 0){
            htmlDom.classList.add('common-backWhiteFilter')
          }
        }
      }).catch((err) => {
        console.log(err)
      })
    }
  },
  computed: {
    // menuLists() {
    //   // 根据 公司no 本地添加 商机服务和吃住行顶部菜单
    //   // let menuList = JSON.parse(JSON.stringify(this.menuList));
    //   let menuList = JSON.parse(JSON.stringify(this.$store.state.IndexMenuList));
    //   if (this.$store.state.loginType) {
    //     let { userType, companyNo } = this.$store.state.user;
    //     console.log("this.$store.state.user", this.$store.state.user.companyNo);
    //     let { companyNoList, topMenuList } = AppConfig.sysBaseConf;
    //     let flag = menuList.find(item=>item.name === topMenuList[0]['name'])
    //     if (companyNoList.includes(+companyNo) && !flag) {
    //       menuList = menuList.concat(topMenuList);
    //     }
    //   }
    //   return menuList;
    // },
  },
  async mounted() {
    localStorage.removeItem("search_query");
    this.init();
    setTimeout(() => {
      this.scrollListener();
    }, 1000);
    if (process.client) {
      // let url = process.
      // VUE_BASE_URL
      try {
        const base_url = env[process.env.environment].VUE_BASE_URL;
        let config = {
          event: "pvCount",
          url: base_url + "/shigongbang-analysis/v3/visitor/index",
          source: "jyb-home"
        };
        setTimeout(() => {
          try {
            new Probe(config).excute(window);
          } catch (error) {
            console.log("error", error);
          }
        }, 1000);
      } catch (error) {
        console.log("error", error);
      }
    }
    this.$bus.$off("scrollCurent").$on("scrollCurent", data => {
      this.clickLeftBar(data);
    });
    this.handleBlackWhiteFilter()
  },
  beforeDestroy(){
    htmlDom.classList.remove('common-backWhiteFilter')
  }
};
